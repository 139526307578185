import React, {Component} from "react";

import {getCurrentUser} from "../common/ApiUtils";
import C from "../common/ReductTypeConstants";

import {connect} from "react-redux";
import {withRouter} from "react-router";
import {ACCESS_TOKEN} from "../common/constants";


class LoginHandler extends Component {

  constructor(props) {
    super(props);
  }

  componentWillUnmount() {
  }


  componentDidUpdate(prevProps) {
  }

  render() {
    return <></>
  }

  loadCurrentUser() {
    const {dispatchUser, location} = this.props;
    //console.log(location);
    if (location.pathname.startsWith("/forgottenPassword/") ||
        location.pathname.startsWith("/aprslogbook"))
    {
      return;
    }

    getCurrentUser()
        .then(response => {
          //console.log(response)
          dispatchUser(response);
        })
        .catch(error => {
          console.error('Failed to load current user:', error);
          // Redirect to login on any error
          this.props.history.push("/login");
        });
  }


  componentDidMount() {
    this.loadCurrentUser();
  }




  handleLogin() {
    //alert("Logged in");
    // notification.success({
    //   duration: 3,
    //   message: 'Lieferantenpreise',
    //   description: intl.formatMessage({id:"login.successfully",
    //     defaultMessage: "You're successfully logged in."})
    //
    // });
    this.loadCurrentUser();

  }

}


const mapStateToProps = function(state){
  return {
    currentUser: state ? state.currentUser: null,
    isAuthenticated: state ? state.isAuthenticated : false,
    isLoading: state ? state.isLoading : false
  }
}

const mapDispatchToProps = function (dispatch) {
  return {
    dispatchUser(load) {
      {
        dispatch({type: C.USER, payload: load})
      }
    },
  }
}



export default connect(mapStateToProps, mapDispatchToProps)(withRouter(LoginHandler));